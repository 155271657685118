<template>
  <div>
    <Navbar page="Gestão de contratos" video="https://youtu.be/UpVnMUqK2Ss"/>
    <div class="mx-4 my-4 md:mx-8 md:my-4">

      <div class="border-b border-gray-200 dark:border-gray-700 mb-5">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="mr-2 cursor-pointer">
                <a @click="tipo = 'fornecedor'; colaborador = null; updateFiltro()" :class="tipo === 'fornecedor' ? 'text-blue-600 border-blue-600': ''" class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-blue-600 hover:border-blue-300 group" aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    Contratos agentes de tratamento
                </a>
            </li>
            <li class="mr-2 cursor-pointer">
                <a @click="tipo = 'colaborador'; fornecedor = null; updateFiltro()" :class="tipo === 'colaborador' ? 'text-blue-600 border-blue-600': ''" class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-blue-600 hover:border-blue-300 group" aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    Contrato pessoas da empresa
                </a>
            </li>
        </ul>
      </div>
      <router-link type="button" :to="`/${route}/multiplos?tipo=${tipo}`"
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full mr-2 md:w-72 font-semibold text-center
        mb-2
        "
      >
          <span class="inline-block mr-2">Novo contrato</span>
      </router-link>

      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-4">
            <label for="nome" class="block text-sm font-medium">Nome</label>
            <input v-model="nome" type="text" name="nome" id="nome" placeholder="Filtrando nome contrato" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div v-if="tipo === 'fornecedor'" class="col-span-12 md:col-span-3">
            <label for="fornecedor" class="block text-sm font-medium">Agente de tratamento</label>
            <select v-model="fornecedor" name="fornecedor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
            </select>
        </div>
        <div v-if="tipo === 'colaborador'" class="col-span-12 md:col-span-3">
            <label for="colaborador" class="block text-sm font-medium"> Pessoa da empresa </label>
            <select v-model="colaborador" name="colaborador" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
            </select>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <label for="vencidas" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="vencidas" class="rounded-sm" id="vencidas">
            <span class="ml-2">
                Visualizar contrato vencido
            </span>
          </label>
          <label for="desativadas" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="desativadas" class="rounded-sm" id="desativadas">
            <span class="ml-2">
                Visualizar arquivados
            </span>
          </label>
        </div>
        <div class="col-span-12 md:col-span-2">
          <label class="block text-sm font-medium">Buscar</label>
          <button @click="updateFiltro" class="mt-1 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col justify-center">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-2 py-3 flex items-center justify-center text-center text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Título
                      </th>
                      <th v-if="tipo === 'fornecedor'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Organização
                      </th>
                      <th v-if="tipo === 'fornecedor'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Posição
                      </th>
                      <th v-if="tipo === 'colaborador'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Pessoa da empresa
                      </th>
                      <th v-if="tipo === 'colaborador'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Função
                      </th>
                      <th v-if="tipo === 'colaborador'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Setor
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Contrato ativo 
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Vencimento
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Data Envio
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Versão do doc
                      </th>
                      <th v-if="tipo === 'fornecedor'" scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Nível conformidade
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-2 py-3 whitespace-nowrap">
                        <div class="flex items-center justify-center"> 
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-center text-gray-500">
                            {{item.nome ? item.nome : ''}}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'fornecedor'" class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{ item.fornecedor && item.fornecedor.nome ? item.fornecedor.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'fornecedor'" class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{ item.fornecedor && item.fornecedor.agente && item.fornecedor.agente.nome ? item.fornecedor.agente.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'colaborador'" class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{ item.colaborador && item.colaborador.nome ? item.colaborador.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'colaborador'" class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{ item.colaborador && item.colaborador.funcao && item.colaborador.funcao.nome ? item.colaborador.funcao.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'colaborador'" class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{ item.colaborador && item.colaborador.setor && item.colaborador.setor.nome ? item.colaborador.setor.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500" :class="item.contrato_ativo ? 'text-green-500': 'text-red-500'">
                            {{item.contrato_ativo ? 'Sim' : 'Não'}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div v-if="item.vencimento_contrato" class="text-sm font-medium text-gray-500" :class="new Date(item.vencimento_contrato) > new Date() ? 'text-green-500': 'text-red-500'">
                            {{ item.vencimento_contrato | moment("DD/MM/YYYY") }}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div v-if="item.dataEnvio" class="text-sm font-medium text-green-500">
                            {{ item.dataEnvio | moment("DD/MM/YYYY HH:mm")}}
                          </div> 
                          <div v-else class="text-sm font-semibold text-red-600">
                            Não enviado
                          </div> 
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{item.versao ? item.versao  : ''}}
                          </div>
                        </div>
                      </td>
                      <td v-if="tipo === 'fornecedor'"  class="px-2 py-3">
                        <div class="flex items-center justify-center"> 
                          <div class="text-sm text-gray-500">
                            {{item.fornecedor ? item.fornecedor.nivel_conformidade +'%' : ''}}
                          </div>
                        </div>
                      </td>
                      <td v-if="!desativadas" class="px-2 py-3 text-center text-sm font-medium">
                          <router-link type="button" :to="`/${route}/form/${item._id}`" class="inline-block text-white hover:bg-green-700 bg-green-600 rounded py-1 px-2 mt-1 mx-1">
                              Detalhes
                          </router-link>
                          <router-link v-if="item.fornecedor" type="button" :to="`/cadastros/agentestratamento/form/${item.fornecedor._id}`" class="inline-block text-white hover:bg-yellow-400 bg-yellow-500 rounded py-1 px-2 mt-1 mx-1">
                              Editar agente
                          </router-link>
                          <router-link v-if="item.colaborador" type="button" :to="`/cadastros/pessoas/form/${item.colaborador._id}`" class="inline-block text-white hover:bg-yellow-400 bg-yellow-500 rounded py-1 px-2 mt-1 mx-1">
                              Editar pessoa
                          </router-link>
                          <button
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-2 mx-1 mt-1">
                            Desativar
                          </button>
                      </td>
                      <td v-else class="px-2 py-3 text-sm font-medium">
                        <router-link 
                          :to="`/${route}/form/${item._id}`"
                          class="inline-block text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-2 mx-1 my-1">
                          Ativar
                        </router-link>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'contratos',
      list: [],
      selecionados: [],
      fornecedores: [],
      pessoas: [],
      fornecedor: null,
      colaborador: null,
      vencidas: false,
      desativadas: false,
      page: 1,
      perPage: 10,
      total: 0,
      nome: "",
      tipo: "fornecedor",
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit, tipo: this.tipo, nome: this.nome, fornecedor: this.fornecedor, colaborador: this.colaborador, vencidas: this.vencidas, desativadas: this.desativadas });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async novoContrato(){
      const req = await this.$http.post(`/v1/${this.route}/`, { tipo: this.tipo });
      if (req.data.success) {
          this.$router.push({ path: `/${this.route}/form/${req.data.data._id}?novo=${true}` });
      } else {
          this.$vToastify.error(req.data.err);
      }
    },
    
    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse treinamento ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, tipo: this.tipo, nome: this.nome, fornecedor: this.fornecedor, colaborador: this.colaborador, vencidas: this.vencidas, desativadas: this.desativadas };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, tipo: this.tipo, nome: this.nome, fornecedor: this.fornecedor, colaborador: this.colaborador, vencidas: this.vencidas, desativadas: this.desativadas });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;
    if(this.$route.query.vencidas) this.vencidas = this.$route.query.vencidas === 'true' ? true : false;

    if(this.$route.query.tipo) {
      this.tipo = this.$route.query.tipo;
      this.query.tipo = this.$route.query.tipo;
    }

    if(this.$route.query.fornecedor) this.query.fornecedor = this.$route.query.fornecedor;
    if(this.$route.query.colaborador) this.query.colaborador = this.$route.query.colaborador;
    if(this.$route.query.nome) this.query.nome = this.$route.query.nome;
    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;
    if(this.$route.query.vencidas) this.vencidas = this.$route.query.vencidas === 'true' ? true : false;

    if(this.$route.query.tipo) {
      this.tipo = this.$route.query.tipo;
      this.query.tipo = this.$route.query.tipo;
    }

    if(this.$route.query.fornecedor) this.query.fornecedor = this.$route.query.fornecedor;
    if(this.$route.query.colaborador) this.query.colaborador = this.$route.query.colaborador;
    if(this.$route.query.nome) this.query.nome = this.$route.query.nome;
    this.start();

    const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
    this.pessoas = listPessoas.data.data;

    const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
    this.fornecedores = listFornecedores.data.data; 

    this.$watch('tipo', async (tipo) => {

      this.query.skip = 0;
      this.tipo = tipo;
      this.nome = '';

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit, tipo: tipo, nome: this.nome, vencidas: this.vencidas, desativadas: this.desativadas });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    });

    this.$watch('desativadas', async (desativada) => {

      this.query.skip = 0;
      this.desativadas = desativada;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit, tipo: this.tipo, nome: this.nome, vencidas: this.vencidas, desativadas: desativada });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    });

    this.$watch('vencidas', async (vencida) => {

      this.query.skip = 0;
      this.vencidas = vencida;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit, tipo: this.tipo, nome: this.nome, vencidas: vencida, desativadas: this.desativadas });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    });
  },
}
</script>